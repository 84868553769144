<template lang="pug">
  v-container(fluid)
    .subtitle-1.text-center
      | {{ app.marketingAttributes.voodooName }} does not exist on
      | #[b {{ displayPlatform }} {{ displayStore }}] on Adjust. You can create it by
      | filling in the following form.

    .text-center.pb-4
      v-switch(
        v-model='isPublished',
        label='Published Status',
        hint='Check if the app if available on the store.',
        persistent-hint
      )

    v-fade-transition(leave-absolute)
      v-text-field(
        outlined,
        clearable,
        :rules='[rules.required, rules.bundleId]',
        v-if='!isPublished'
        v-model='currentApp.bundleId',
        :label="`${displayPlatform}'s Bundle ID`",
        @input='validate'
      )

    v-text-field(
      outlined,
      clearable,
      v-if="os === 'android'",
      v-model='sha',
      :rules='[rules.required, rules.sha]',
      ref='sha',
      label='Sha-1',
      hint='Something like 56:FF:F3:5D:28:B1:7A:BE:57:DA:4C:0B:B8:B8:C0:F9:5B:9E:45:7E',
      persistent-hint,
      @input='validate'
    )

    .text-center
      v-btn(
        outlined,
        color='cyan',
        @click='create',
        :disabled='!isValid',
        :loading='creating'
      )
        img(:src='icons.adjustIcon', width='24', height='24')
        .pl-2 Create
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import adjustIcon from '@/assets/icons/logo-adjust.png'

export default {
  name: 'AdjustCreateCard',

  props: {
    platform: String
  },

  data: () => ({
    icons: { adjustIcon },

    isPublished: false,
    sha: null,

    rules: {
      required: (value) => !!value || 'This field is required.',
      sha: (value) => !!value && /^(\w{2}:).*$/.test(value),
      bundleId: (value) => !!value && /^[a-zA-Z0-9]+\.[a-zA-Z0-9]+\.[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)?$/.test(value)
    },

    isValid: false
  }),

  mounted () {
    this.$nextTick(() => this.validate())
  },

  computed: {
    ...mapGetters('apps', {
      app: 'app'
    }),

    os () { return this.platform.split('_')[0] },
    store () { return this.platform.split('_')[1] },
    isIos () { return this.os === 'ios' },
    creating () { return this.$store.state.adjust.creating[this.currentApp.bundleId] },
    currentApp () {
      if (this.app) {
        const app = this.app.apps.find(({ platform }) => platform === this.platform)

        return {
          bundleId: app.bundleId,
          storeId: app.storeId,
          isPublished: app.isPublished,
          releaseStatus: app.releaseStatus,
          id: app.id
        }
      }

      return {
        bundleId: null,
        storeId: null,
        isPublished: null,
        releaseStatus: null,
        id: null
      }
    },
    displayPlatform () {
      return this.isIos ? 'iOS' : 'Android'
    },
    displayStore () {
      return this.store
        ? this.store.charAt(0).toUpperCase() + this.store.slice(1)
        : 'Worldwide'
    }
  },

  methods: {
    ...mapActions('adjust', [
      'createApp',
      'getApp'
    ]),

    validate () {
      const { sha, rules } = this
      const { bundleId } = this.currentApp

      const isValid = [
        this.isPublished ? true : rules.bundleId(bundleId),
        this.isIos ? true : rules.sha(sha)
      ]

      this.isValid = isValid.every(Boolean)
    },

    async create () {
      let name = this.app.marketingAttributes.voodooName

      if (this.platform === 'ios_china') name += ' - China'

      const opts = {
        platform: this.os,
        isPublished: this.isPublished,
        bundleId: this.currentApp.bundleId,
        storeId: this.isIos ? +this.currentApp.storeId : this.currentApp.bundleId,
        androidSha1: this.sha,
        name: `${name} ${this.displayPlatform}`,
        releaseStatus: this.currentApp.releaseStatus || 'released'
      }

      await this.createApp({
        opts,
        appId: this.currentApp.id,
        unifiedAppId: this.app.id
      })
    }
  }
}
</script>
